/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { MDXProvider } from '@mdx-js/react';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { BgImage } from 'gbimage-bridge';
import { memo } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo/seo';

export default memo(({ data }) => {
  const { frontmatter } = data.mdx;
  const { title, image } = frontmatter;
  const { body } = data.mdx;
  const shortcodes = { Link };
  const bg = getImage(image);
  const imgStack = ['linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.5))', bg];

  return (
    <Layout>
      <SEO frontmatter={frontmatter} />
      <BgImage
        image={imgStack}
        css={css`
          &::after,
          &::before {
            background-position: 50% 10%;
            background-size: cover;
          }
        `}
        style={{ backgroundPosition: '', backgroundSize: '' }}
      >
        <div className="container py-12  md:pb-24 md:pt-24 ">
          <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">{title}</h1>
        </div>
      </BgImage>
      {/* <Parallax
        bgImage={image.childImagechildImageSharp.gatsbyImageData.src}
        bgImageAlt={`${title} image`}
        strength={600}
      >
        <div className="container py-12  md:pb-24 md:pt-24 ">
          <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">{title}</h1>
        </div>
        <div style={{ height: '200px' }} />
      </Parallax> */}
      <div className="container py-6">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  );
});

export const pageQuery = graphql`
  query PageHeaderQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH, formats: [AUTO, WEBP])
          }
        }
      }
      body
    }
  }
`;
